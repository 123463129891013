import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import { Cards, CategoryCard } from '@accodeing/gatsby-theme-switsbake'
import Layout from '../components/Layout'

const productImage = (images, productId) =>
  images.filter((image) => image.name === productId)[0]

const Products = ({ images, products, slug }) =>
  products.map((product) => {
    const image = productImage(images, product.articleId).childImageSharp
      .gatsbyImageData

    return (
      <CategoryCard
        title={product.articleName}
        linkTo={'/sortiment/' + slug + '/' + product.articleId}
        key={product.articleName}
      >
        <GatsbyImage
          alt={product.articleName}
          image={image}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </CategoryCard>
    )
  })

const CategoryPage = ({ pageContext, data }) => {
  const crumbs = pageContext.breadcrumb.crumbs.slice(1)
  const { products, title, slug } = pageContext

  return (
    <Layout
      pageTitle={title}
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row'>
        <section className='section section--with-background'>
          <h1 className='page__heading'>{title}</h1>
          <Breadcrumb crumbs={crumbs} />
        </section>
        <section className='section'>
          <Cards>
            <Products
              products={products}
              images={data.images.nodes}
              slug={slug}
            />
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(
      relativePath: { eq: "cookies-cream-serveret-pa-fat.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "products" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 520, quality: 60, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default CategoryPage
